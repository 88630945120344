<template>
  <div id="cps">
    <CreditCard v-if="isCC" />
    <InternationalBankCps v-else-if="isIBT" />
    <CPS v-else />
  </div>
</template>

<script>
import CPS from '@/components/deposit/Cps/IndexCps.vue';
import CreditCard from '@/components/deposit/Cps/CreditCardCps.vue';
import InternationalBankCps from '@/components/deposit/Cps/InternationalBankCps.vue';
import { creditCardData, internationalBankData } from '@/constants/payment/depositSetting.json';

export default {
  name: 'CpsUATView',
  components: { CPS, CreditCard, InternationalBankCps },
  computed: {
    paymentMethod() {
      return this.$store.state.cps.payment.payment_method;
    },
    isCC() {
      return creditCardData.includes(this.paymentMethod);
    },
    isIBT() {
      return internationalBankData.includes(this.paymentMethod);
    }
  }
};
</script>
